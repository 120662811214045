/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] on begin
import moment from 'moment-timezone';
// % protected region % [Add any extra imports here] end

// % protected region % [Override contents here] off begin
export const SERVER_URL = '';
// % protected region % [Override contents here] end

// % protected region % [Override application name here] off begin
export const APPLICATION_NAME = 'hitachi';
// % protected region % [Override application name here] end

// % protected region % [Add any extra constants here] on begin
// version number
export const MAT_VERSION = '2.51.0.0';

//
export const FEATURE_COMING_SOON_TEXT = 'Feature coming soon';
export const PASSWORD_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#\$%\^&\*])(?=.{12,})');
export const MAP_LOCK_REFRESH_INTERVAL_SECONDS = 10;
export const SAVE_CHANGES_INTERVAL_SECONDS = 15;
export const MAP_LOCK_SESSION_KEY = 'mapLockSessionObject';
export const COLOR_SUPPORT_RED = 0xFF3A3A;

export const MAX_PATH_DISTANCE = 200;

export const PATH_ERROR_NODE_LIMIT = 'Exceeded limit of 100 nodes';
export const PATH_ERROR_UNKNOWN = 'Clothoid path error';

export const MAX_EDITABLE_MID_REVERSE_POINTS = 3;

// Snapping vicinity radius
export const VICINITY_RADIUS = 3;

// Max object ids
export const SUBLINK_ID_MAX = 20000;

// Node and Speed limit dynamic scale threshold
export const OBJECT_SCALE_THRESHOLD = 2.75;
// Measuring tool dynamic scale threshold
export const RULER_SCALE_THRESHOLD = 5;

// Changing this constant will require an update of areaname for all bays using it
export const UNDEFINED_BAYS_AREANAME = 'Undefined Bays';

// HITMAT-501: The minimum distance between each of the waypoints
export const MINIMUM_DISTANCE_BETWEEN_WAYPOINTS = 9;

export const MINIMUM_DISTANCE_BETWEEN_WAYPOINTS_ERROR = 'Unable to generate a path to the previous waypoint. The minimum distance to the previous waypoint should be at least 9 m';

export const MAX_NODES_PER_LINK = 100;
export const MAX_SUBLINKS_PER_LINK = 25;

// Error constants
export const HEADING_ERROR = 'Heading value must be between 0 and 360 inclusive';
export const NORTHING_LESS_THAN_ZERO_ERROR = 'Northing value must be greater than zero';
export const NORTHING_INSIDE_BOUNDS_ERROR = 'Value needs to be above or equal to the northing of the bottom of the map and below or equal to the northing of the top of the map';
export const NORTHING_DEFAULT_ERROR = 'Invalid northing value';
export const EASTING_LESS_THAN_ZERO_ERROR = 'Easting value must be greater than zero';
export const EASTING_INSIDE_BOUNDS_ERROR = 'Value needs to be above or equal to the easting of the left of the map and below or equal to the easting of the right of the map';
export const EASTING_DEFAULT_VALUE = 'Invalid easting value';
export const ERROR_SNAP_OPERATION_NOT_ALLOWED = 'This snap operation is not allowed';
export const ERROR_MAXIMUM_SUBLINK_ID = 'This operation is not allowed. The maximum allowed number of sublinks would be exceeded.';
export const ERROR_MAXIMUM_LINK_ID = 'This operation is not allowed. The maximum allowed number of links would be exceeded.';
export const ERROR_NO_PATH_IN_AHC_MAP = 'The FMS map cannot be generated because the AHC map does not contain any paths.';
export const ERROR_NO_AUTONOMOUS_AREA_IN_AHC_MAP = 'The FMS map cannot be generated because the AHC map does not contain any autonomous areas.';

export const CONNECTIVITY_PAIR_ERROR = 'This connectivity is not allowed. An identical connectivity pair already used by another link';

// Timezone
const timezoneList = moment.tz.names();
export const TIMEZONE_COMBOBOX_OPTIONS = timezoneList.map(tz => { return { display: tz, value: tz } });
export const MOMENT_FORMAT_STRING = 'DD/MM/YYYY HH:mm:ss zz (UTCZ)';
export enum MID_WAYPOINT_CHANGE_RESULT {
    Success = 1,
    Ignore = 2,
    Confirmable = 3
}
export enum JOIN_SUBLINK_RESULT {
	Success = 1,
	MaxNodesExceeded = 2,
	NoLink,
	NextSublinkNotFound,
	FirstOrLastNodeNotFound
}
export enum JOIN_LINK_ALLOWED_RESULT {
	Allowed = 1,
	MaxNodesExceeded = 2,
	MaxSublinksExceeded = 3,
}
// % protected region % [Add any extra constants here] end
