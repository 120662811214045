import MapStateHandler from "../MapStateHandler";
import {LeafletMouseEvent} from "leaflet";
import {LeafletCoordinates, RealWorldCoordinates} from "../../Helpers/Coordinates";
import {isCtrlKeyHeld, isShiftKeyHeld} from "../MapGlobalEventHandler";
import PathManager from "./Helpers/PathManager";
import {calcHeading} from "../../Helpers/MapUtils";

type PathToolHandlerParams = PathManager | undefined;

export default class PathToolHandler extends MapStateHandler<PathToolHandlerParams> {

	// Holds the main state for handling creation of the path,
	private state: PathManager;

	private ignoreDispose: boolean = false;

	private disableAddWaypoint = false;

	onInit(initialState: PathToolHandlerParams): Promise<void> | void {
		this.state = initialState !== undefined
			? initialState as PathManager : new PathManager(this);

		this.state.init();
	}

	onClick(event: LeafletMouseEvent): Promise<void> | void {
		if (this.state.isUserPerformingAction() || this.state.isProcessing()) {
			return;
		}

		if (this.disableAddWaypoint) {
			return;
		}

		this.state.startAction();

		const coords = this.getRenderer().getRealWorldCoords(event.latlng);

		if (!this.handleSpecialOperations(event, coords)) {
			// If handle special operation returns false (ie. it hasn't done anything). We add a normal waypoint
			this.state.addWaypoint(coords, {
				isSnapped: false,
				shortClicked: true,
			});
		}

		this.state.stopAction();

		this.handleWaypointLimits();
	}

	onDoubleClick(event: LeafletMouseEvent): Promise<void> | void {
		if (this.state.waypoints.isFullPath()) {
			this.transitionToEditMode();
		}
	}

	onDragStart(event: LeafletMouseEvent, originalCoordinates: LeafletCoordinates): Promise<void> | void {
		if (this.state.isUserPerformingAction() || this.state.isProcessing()) {
			return;
		}

		if (this.disableAddWaypoint) {
			return;
		}

		this.state.startAction();

		const coords = this.getRenderer().getRealWorldCoords(originalCoordinates);

		if (this.handleSpecialOperations(event, coords, false)) {
			this.state.startIgnoringDragAction();
			this.handleWaypointLimits();
			return;
		}

		this.state.addWaypoint(coords, {
			isSnapped: false,
			shortClicked: false,
		});
	}

	onDragMove(event: LeafletMouseEvent): Promise<void> | void {
		if (this.state.isIgnoringDragAction() || !this.state.isUserPerformingAction()) return;

		const coords = this.getRenderer().getRealWorldCoords(event.latlng);
		const waypoint = this.state.waypoints.mostRecentlyPlacedWaypoint;

		if (!waypoint) {
			return;
		}

		const heading = calcHeading(waypoint.coordinates, coords);

		waypoint.setHeading(heading);

		this.state.processPathUpdate();
	}

	onDragEnd(event: LeafletMouseEvent): Promise<void> | void {
		if (!this.state.isUserPerformingAction()) return;

		// Stop updating based on heading direction
		this.state.stopAction();

		this.handleWaypointLimits();
	}

	onKeyPress(event: KeyboardEvent): Promise<void> | void {
		if (this.state.isUserPerformingAction() || this.state.isProcessing()) {
			return;
		}

		if (this.getEventHandler().isEnter(event) && this.state.waypoints.isFullPath()) {
			this.transitionToEditMode();
			return;
		} else if (this.getEventHandler().isBackspace(event)) {
			this.state.waypoints.removeLastWaypoint();

			// We may need to handle this as if the direction or task has changed
			this.onRequestUpdate();
			return;
		}

		const shiftHeld = event.shiftKey;
		if (!shiftHeld) {
			return;
		}
		this.handlePropertiesPanelKeyPress(event.key);
	}

	onEscapePressed(event: KeyboardEvent): Promise<void> | void {
		if (this.state.waypoints.hasWaypoint()) {
			this.state.cancelPath();

			// Re-enable adding waypoints
			this.disableAddWaypoint = false;
		} else {
			this.getEventHandler().setActiveTool('selector');
		}
	}

	onRequestUpdate(entity?: unknown): Promise<void> | void {
		// Check if the task has changed and update the waypoint
		this.state.propertiesHelper.handleUpdateTask();
		this.state.propertiesHelper.handleUpdateDirection();

		// Check if we the task has updated and we need to change the cursor
		this.handleWaypointLimits();

		this.state.processPathUpdate();
	}

	onConfirm(): Promise<void> | void {
		if (this.state.confirmPath()) {
			this.state.reset();

			this.getEventHandler().setActiveTool('path');
		}
	}

	dispose(): Promise<void> | void {
		if (this.ignoreDispose) {
			return;
		}

		this.state.dispose();
	}

	private setIgnoreDispose() {
		this.ignoreDispose = true;
	}

	private transitionToEditMode() {
		// Move out of build mode
		this.state.setEditing();

		this.setIgnoreDispose(); // Transitioning to the next state should ignore disposal

		// Ensure we are moving to the correct edit_path2 state (edit_path is for the original tool)
		this.getEventHandler().setMapEventState('edit_path', this.state);
	}

	/**
	 * Handles keypresses that update the properties panel
	 * @param key
	 * @private
	 */
	private handlePropertiesPanelKeyPress(key: string) {
		const {propertiesHelper} = this.state;
		const {
			isTaskReadOnly,
			isDirectionReadOnly,
			direction,
			nextWaypointTask
		} = this.state.propertiesHelper.getPathProperties();

		if (key === 'D' && !isDirectionReadOnly) {
			const newDirection = direction === 'forward' ? 'reverse' : 'forward';
			propertiesHelper.setDirectionPropertyAndUpdate(newDirection);
		} else if (!isTaskReadOnly) {
			if (key === 'T') {
				// Little bit complicated, but this just cycles through the task options. hauling -> reversepoint -> parking -> hauling
				const newTask = nextWaypointTask === 'HAULING'
					? 'REVERSEPOINT' : nextWaypointTask === 'REVERSEPOINT' ? 'PARKING' : 'HAULING';
				propertiesHelper.setTaskPropertyAndUpdate(newTask);
			} else if (key === 'P') {
				propertiesHelper.setTaskPropertyAndUpdate('PARKING');
			} else if (key === 'H') {
				propertiesHelper.setTaskPropertyAndUpdate('HAULING');
			} else if (key === 'R') {
				propertiesHelper.setTaskPropertyAndUpdate('REVERSEPOINT');
			}
		}
	}

	private handleWaypointLimits() {
		// Check if we are allowed to add a new waypoint
		let isAllowedNewWaypoint;
		const nextWaypointReverse = this.state.propertiesHelper.getPathProperties().nextWaypointTask === 'REVERSEPOINT';
		if (nextWaypointReverse) {
			isAllowedNewWaypoint = this.state.validationHelper.isAllowedToAddWaypoint('REVERSEPOINT');
		} else {
			// If the node state is set to parking, we only need to check if we are allowed to add a new hauling node
			isAllowedNewWaypoint = this.state.validationHelper.isAllowedToAddWaypoint('HAULING');
		}

		if (!isAllowedNewWaypoint) {
			this.state.renderHelper.setDisabledCursor();
			this.disableAddWaypoint = true;
		} else {
			this.state.renderHelper.setDefaultCursor();
			this.disableAddWaypoint = false;
		}
	}

	/**
	 * Handles a special operation (eg. adding a straight waypoint, or snapping)
	 *
	 * This function makes sure in cases where the user accidentally drags the mouse, it willl be treated the same as
	 * if the user was to do a short click
	 *
	 * @param event the original mouse event
	 * @param coords the coordinates of the mouse event
	 * @param isStraightOperationAllowed whether the operation is allowed to be a straight operation
	 * 		  (eg. during a drag event we don't want to allow a straight operation)
	 * @returns true if the operation performeed an action, false otherwise
	 */
	private handleSpecialOperations(event: LeafletMouseEvent, coords: RealWorldCoordinates, isStraightOperationAllowed: boolean = true): boolean {
		const isSnapOperation = isCtrlKeyHeld(event.originalEvent);
		const isStraightOperation = isShiftKeyHeld(event.originalEvent);

		if (isSnapOperation && isStraightOperation) {
			return false;
		}

		if (isSnapOperation) {
			const snapOption = this.state.snapHelper.checkForSnap(coords);
			if (snapOption) {
				this.state.addWaypoint(snapOption.targetLocation, {
					shortClicked: false,
					heading: snapOption.targetHeading,
				}, snapOption);
			}

			if (snapOption?.type === 'start') {
				this.transitionToEditMode();
			}

			return true;
		}

		if (!isStraightOperationAllowed) {
			return false;
		}

		const isFullPath = this.state.waypoints.isFullPath();
		const isStartWaypointLongClicked = this.state.waypoints.hasWaypoint() && !this.state.waypoints.isStartShortClicked();
		const isStartWaypointSnapped = this.state.waypoints.isStartSnapped();
		const isStraightOperationNeeded = this.state.waypoints.hasWaypoint() && isStraightOperation;

		if (isFullPath || isStartWaypointLongClicked || isStraightOperationNeeded || isStartWaypointSnapped) {
			this.state.startIgnoringDragAction();
			this.state.addStraightWaypoint(coords, {
				shortClicked: true
			})

			return true;
		}

		return false;
	}
}