/* eslint-disable max-len */
import MapStateHandler from './MapStateHandler';
import { Bay, MapEventHandler, MapRenderer } from '../../index';
import {
	AreaEntity,
	BayEntity,
	ImportVersionEntity,
	LinkEntity,
	MapToolParamEntity,
	NodeEntity,
	SublinkEntity,
} from '../../../../Models/Entities';
import type { LeafletMouseEvent } from 'leaflet';
import * as Coordinates from '../Helpers/Coordinates';
import { action, runInAction } from 'mobx';
import MapController from '../MapController';
import { Polygon } from 'pixi.js';
import Area from '../MapObjects/Area/Area';
import alertToast from '../../../../Util/ToastifyUtils';
import { isCtrlKeyHeld } from './MapGlobalEventHandler';
import { geoJsonPointToRealWorldCoords, getJsonObject, realWordCoordsToGeoJSONPoint } from '../Helpers/GeoJSON';
import { bayType, nodetask } from 'Models/Enums';
import { offsetAlongHeadingRealWorld, setCustomTag } from '../Helpers/MapUtils';
import BayValidator, {
	BAY_AHS_BOUNDARY_ERROR,
	BAY_LOCATION_ERROR
} from '../MapValidators/BayValidator';
import { BayTypeParams } from './BayToolHandler';
import {RealWorldCoordinates} from "../Helpers/Coordinates";
import CreateBayCommand from "../../ChangeTracker/ChangeTypes/CreateBayCommand";
import DeleteBayCommand from "../../ChangeTracker/ChangeTypes/DeleteBayCommand";
import UpdateBayCommand from "../../ChangeTracker/ChangeTypes/UpdateBayCommand";

const VICINITY_RADIUS = 3;
export const SNAPPED_DISTANCE = VICINITY_RADIUS - 0.5; // Should less than VICINITY_RADIUS for dynamically calculating snapping situation

interface IBayEditHandler {
	mapObject?: Bay;
}

interface IBaySnapParams {
	isSnappable: boolean;
	targetNodeIsStart: boolean | undefined;
	targetNodeCoords: Coordinates.RealWorldCoordinates | undefined;
	targetNodeTask: nodetask;
	previousNode?: NodeEntity;
	nextNode?: NodeEntity;
	newAngle: number | undefined;
	offset: number;
}

export default class BayEditHandler extends MapStateHandler<IBayEditHandler> {
	private isNewBay: boolean;
	private isBayConfirmed = false;
	private bayEntity: BayEntity;
	private bayMapObject: Bay;

	private isDraggingCorner = false;
	private isDraggingCross = false;

	private _originalBayLocation: Coordinates.RealWorldCoordinates;
	private originalCursorHeading: number;
	private originalBayHeading: number;
	private originalBayArea: AreaEntity | undefined;
	private originalBayType: bayType;
	private mapParams: MapToolParamEntity;

	private bayTypeParams: BayTypeParams | undefined;

	// @ts-ignore
	async onInit({ mapObject }: IBayEditHandler) {
		if (!mapObject) {
			this.getEventHandler().setActiveTool('selector');
			return;
		}
		const mapParams = this.getController().getImportVersion().maptoolparam;
		if (!mapParams) {
			console.error('Mapparams not found');
			return;
		}

		this.mapParams = mapParams;

		this.bayMapObject = mapObject;
		this.bayEntity = this.bayMapObject.getEntity();
		this.originalBayLocation = this.getBayLocation();

		this.isNewBay = this.getEventHandler().getPreviousState() === 'bay';
		runInAction(() => {
			if (!this.bayEntity.bayId) {
				this.bayEntity.bayId = 0;
			}
			if (!this.bayEntity.baySeq) {
				this.bayEntity.baySeq = 0;
			}
			if (!this.bayEntity.bayVersion) {
				this.bayEntity.bayVersion = '0';
			}
			if (!this.bayEntity.elevation) {
				this.bayEntity.elevation = 0;
			}
		});
		// Get bay type parameters based on values for the properties panel
		this.bayTypeParams = BayValidator.getBayTypeParamsBeforeConfirm(this.bayEntity, this.getController(), this.isNewBay);
		const snapParams = BayEditHandler.isSnappableAndSetBayHeading(this.bayEntity, this.getController(), this.getBayLocation(), false);
		const isSnapped = BayEditHandler.isBaySnapped(this.bayEntity, snapParams);
		const isSnapToEndParkingNode = !!snapParams.previousNode;
		// set spotdir in the properties panel to read-only
		this.getEventHandler().emit('onPropertiesPanel', 'bay', this.bayEntity, {
			isSnapped: isSnapped && isSnapToEndParkingNode,
			isReadOnly: this.bayTypeParams?.isReadOnly,
			bayTypeOptions: this.bayTypeParams?.bayTypeOptions,
		});

		console.log(`BayEditHandler onInit: isNewBay =  ${this.isNewBay} bayId: ${this.bayEntity.id}`);
		if (this.bayEntity.isImported) {
			console.log("Disable edit handles on imported bay");
			return;
		}
		this.bayMapObject.enableHandles();
		BayEditHandler.updateBayMapObject(this.bayMapObject, this.getRenderer());
		// Set the original area for the bay so that when the bay is moved
		// to a different area, it is easy to delete from the old one
		this.originalBayArea = BayValidator.getBayArea(this.getBayLocation(), this.getController());

		// bay validation
		if (this.isNewBay) {
			const bayEntity = await BayValidator.checkBayErrors(this.bayEntity, this.mapParams, this.getController());
			this.getEventHandler().emit('toggleConfirmCreation', true, this.isDisableConfirm(bayEntity));
		}

		// Add listener to confirm creation event
		this.getEventHandler().addListener('onConfirmMapObjectCreation', this.onConfirmBay);
		
	}

	private get originalBayLocation() {
		return this._originalBayLocation;
	}

	private set originalBayLocation(newLocation: Coordinates.RealWorldCoordinates) {
		if (!newLocation || !newLocation.easting) {
			// TODO: remove this test code
			console.log('Original location is invalid');
		} else {
			this._originalBayLocation = newLocation;
		}
	}

	onRequestUpdate() {
		BayEditHandler.updateBayMapObject(this.bayMapObject, this.getRenderer());

		// If the bay is not confirmed, we don't want to update it in the database just yet
		if (!this.isNewBay) {
			this.getController().getTracker().addChange(new UpdateBayCommand(this.bayEntity));
		}
	}

	async onClick(event: LeafletMouseEvent) {
		await this.waitForConfirmingStatus();
		if (!this.isNewBay) {
			const mapObject = this.getController().getMapObjectAtCoordinates(event.latlng);

			if (!mapObject || mapObject?.getId() !== this.bayMapObject.getId()) {
				this.getEventHandler().setMapEventState('selector', { mapObject });
			}
		}
	}

	/**
	 * Confirms creation of new bay and set state handler to bay (from edit_bay)
	 * @param event
	 */
	async onDoubleClick(event: LeafletMouseEvent) {
		await this.waitForConfirmingStatus();
		if (!this.getController().getMapLookup().confirmButtonWillShow) {
			if (this.isNewBay) {
				const mapObject = this.getController().getMapObjectAtCoordinates(event.latlng);
	
				if (!mapObject || mapObject?.getId() !== this.bayMapObject.getId()) {
					const disableConfirm = this.isDisableConfirm(this.bayEntity); // HITMAT-767
					if (!disableConfirm) {
						this.getEventHandler().emit('onConfirmMapObjectCreation');
						this.dispose();
					}
				}
			}
		}
	}

	/**
	 * Change cursor depending on whether the user is dragging the center cross (drag bay location)
	 * or one of the edges (rotate bay)
	 * @param event
	 */
	onMove(event: LeafletMouseEvent) {
		if(!!this.bayEntity){
			if (!this.bayEntity.isImported) {
				const coordinates = this.getRenderer().getRealWorldCoords(event.latlng);
				const isOverCorner = !!this.getController()
					.hitTestContainer(coordinates, this.bayMapObject.getHandles());

				const isOverCross = !!this.getController()
					.hitTestContainer(coordinates, this.bayMapObject.getCrossHandle());

				if (isOverCorner) {
					this.getController().setRotateCursor();
				} else if (isOverCross) {
					this.getController().setCursor('move');
				} else {
					this.getController().setDefaultCursor();
				}
			}
		}
	}

	/**
	 * Determine which type of drag operation to initiate (rotate or change location)
	 * by doing hittest and store original heading/location values
	 * @param event
	 * @param originalCoordinates
	 */
	onDragStart(event: LeafletMouseEvent, originalCoordinates: Coordinates.LeafletCoordinates) {
		if (!this.bayEntity.isImported) {
			this.isDraggingCorner = !!this.getController()
				.hitTestContainer(originalCoordinates, this.bayMapObject.getHandles());

			this.isDraggingCross = !!this.getController()
				.hitTestContainer(originalCoordinates, this.bayMapObject.getCrossHandle());
				console.log(`onDragStart: isDraggingCross ${this.isDraggingCross}`);

			console.log(`onDragStart: isDraggingCorner = ${this.isDraggingCorner} isDraggingCross = ${this.isDraggingCross}`);

			if (this.isDraggingCross) {
				this.originalBayLocation = this.getBayLocation();
				this.originalBayHeading = this.bayEntity.heading;
				this.originalBayType = this.bayEntity.bayType;
			}
			if (this.isDraggingCorner) {
				this.originalBayLocation = this.getBayLocation();
				const coordinates = this.getRenderer().getRealWorldCoords(originalCoordinates);
				this.originalCursorHeading = BayEditHandler.getAngleBetweenPoints(this.getBayLocation(), coordinates);
				this.originalBayHeading = this.bayEntity.heading;
			}
		}
	}

	/**
	 * Dynamically update heading/location values as per current mouse coordinates
	 * @param event
	 */
	onDragMove(event: LeafletMouseEvent) {
		if (!this.bayEntity.isImported) {
			const coordinates = this.getRenderer().getRealWorldCoords(event.latlng);
			this.updateBay(coordinates);
		}
	}

	public static _isDisableConfirm(bay: BayEntity, eventHandler: MapEventHandler) {
		// this static method is needed for use in bayvalidator
		// HITMAT-930 - confirm always enabled (this bay change in future tickets, so keep logic below)
		return false;
		const { mapObjectErrorss } = bay;
		// HITMAT-758
		// The parking/fuelling bay error where it is not placed close enough to a parking node should not disable the Confirm button and the bay should be able to be created even with this error.
		let isDisabled = false;
		if (mapObjectErrorss.length > 1) {
			console.log(`isDisableConfirm: disable (more than 2 errors)`);
			isDisabled = true;
		} else if (mapObjectErrorss.length === 1) {
			const { errorMessage } = mapObjectErrorss[0];
			if (errorMessage === 'BayWrongNodeError') {
				console.log(`isDisableConfirm: enable (there is one error but it's a BayWrongNodeError)`);
				isDisabled = false;
			} else {
				console.log(`isDisableConfirm: disable (there is one error )`);
				isDisabled = true;
			}
		}
		// HITMAT-767 When the Confirm button is disabled, the Add button from the multi-bay creation tool should also be disabled.
		eventHandler.emit('onSetMultiBayDisabledState', isDisabled);
		console.log(`isDisableConfirm: send onSetMultiBayDisabledState with isDisabled ${isDisabled}`);
		return isDisabled; 
	}

	public isDisableConfirm(bay: BayEntity) {
		if (!this.getController().isActionConfirmAllowed()) {
			return true;
		}

		return BayEditHandler._isDisableConfirm(bay, this.getEventHandler());
	}

	@action
	public static setBayArea(bay: BayEntity, bayArea: AreaEntity) {
		bay.areaId = bayArea.id;
		bay.areaName = bayArea.areaName;
		bay.area = bayArea;
	}

	/**
	 * Validate new position of bay after dragging and set back to
	 * original location if new location is invalid
	 * @param event
	 */
	@action
	async onDragEnd(event: LeafletMouseEvent) {
		const _isCtrlKeyHeld = isCtrlKeyHeld(event.originalEvent);
		const controller = this.getController();
		const coordinates = this.getRenderer().getRealWorldCoords(event.latlng);

		if (this.bayEntity.isImported) {
			console.log('Imported bays cannot be dragged');
			return;
		}
		if (!BayValidator.validateBayCoordsInMapBounds(coordinates, controller)) {
			alertToast(BAY_AHS_BOUNDARY_ERROR, 'error');
			this.resetBayLocation();
			return;
		}

		if (!this.isDraggingCorner && this.isDraggingCross) {
			const errorMsg = BayValidator.validateInsideOriginalArea(coordinates, this.bayEntity, controller);
			if (!!errorMsg) {
				alertToast(errorMsg, 'error');
				this.resetBayLocation();
				return;
			}
		}

		const originalBayEntity = new BayEntity(this.bayEntity);
		originalBayEntity.bayLocation = JSON.stringify(realWordCoordsToGeoJSONPoint(this.originalBayLocation));
		originalBayEntity.heading = this.originalBayHeading;

		this.bayEntity.state = this.bayEntity.isImported ? 'MODIFIED' : 'NEW_OBJECT';

		// Moving a bay involves two cases: 1. Normal drag/drop. 2. Snap to node
		if (this.isDraggingCross) {
			let isSnapped = false; 
			// If bay is snappable, update and track 1. heading 2. bayLocation 3. Spotdir
			if (_isCtrlKeyHeld) {
				const isUpdateHeading = true;
				// Adjust heading
				const snapParams = BayEditHandler.isSnappableAndSetBayHeading(this.bayEntity, controller, coordinates, isUpdateHeading);
				if (snapParams.isSnappable) {
					isSnapped = true;
					setCustomTag('map-interface', 'snap-a-bay-to-a-node');
				}
				// Set bay location according to offset determined in snapParams
				BayEditHandler.setBayLocation(this.bayEntity, coordinates, snapParams);

				const snappedBayLocationCoords: number[] = JSON.parse(this.bayEntity.bayLocation).coordinates;
				const bayCoordsSnapped: RealWorldCoordinates = Coordinates.realWorldCoordinates(snappedBayLocationCoords[1], snappedBayLocationCoords[0]);
				if (!BayValidator.getBayArea(bayCoordsSnapped, controller)) {
					this.resetBayLocation();
					throw new Error(BAY_LOCATION_ERROR);
				}
				
				BayEditHandler.setBaySpotdir(this.bayEntity, snapParams);
				const isSnapToEndParkingNode = !!snapParams.previousNode;
				// set spotdir in the properties panel to read-only
				this.getEventHandler().emit('onPropertiesPanel', 'bay', this.bayEntity, {
					isSnapped: isSnapped && isSnapToEndParkingNode
				});
			} else {
				setCustomTag('map-interface', 'move-a-bay');
			}

			// emit the update event on BayLocation change
			const attr = isSnapped ? 'snapBayUpdates' : 'bayLocation';
			await this.getController().getTracker().addChange(new UpdateBayCommand(this.bayEntity));
		}

		// Rotating a bay
		if (this.isDraggingCorner) {
			setCustomTag('map-interface', 'rotate-a-bay');
			// emit the update event on BayHeading change
		}
		
		if (this.isNewBay) {
			// An unconfirmed bay can only be a single bay, as creating multibay automatically confirms bays
			const bayEntity = await BayValidator.checkBayErrors(this.bayEntity, this.mapParams, controller);
			this.getEventHandler().emit('toggleConfirmCreation', true, this.isDisableConfirm(bayEntity));
		} else {
			await this.getController().getTracker().addChange(new UpdateBayCommand(this.bayEntity));
			// FIXME: this runs a loop
			await this.updateErrorStateOnAllBaysInArea();
		}

		this.isDraggingCorner = false;
		this.isDraggingCross = false;
	}
	
	private resetBayLocation() {
		this.updateBay(this.originalBayLocation);
		this.isDraggingCorner = false;
		this.isDraggingCross = false;
	}

	/**
	 * Escape key performs one of two possible actions depending on state
	 * 1) Pressed when there's unconfirmed bay: delete it enter bay mode (from bay_edit)
	 * 2) Pressed when there's no unconfirmed bay: leave bay tool and open selector tool
	 * @param event
	 */
	onEscapePressed(event: KeyboardEvent): Promise<void> | void {
		if (this.isNewBay) {
			if (!this.getController().getMapLookup().confirmButtonWillShow) {
				this.getEventHandler().setMapEventState('bay');
			}
		} else {
			this.getEventHandler().setMapEventState('selector');
		}
		if (!this.getController().getMapLookup().confirmButtonWillShow) {
			// Hide the confirm button from toolbar
			this.getEventHandler().emit('toggleConfirmCreation', false);	
		}
	}

	/**
	 * Delete unconfirmed bay when Delete or Backspace is pressed
	 * Confirm bay when Enter is pressed (same as double click)
	 * @param event
	 */
	async onKeyPress(event: KeyboardEvent) {
		await this.waitForConfirmingStatus();                                                                                                                                                                                         
		if (!this.getController().getMapLookup().confirmButtonWillShow) {
			if (['Delete', 'Backspace'].includes(event.key)) {
				if (this.bayEntity.isImported) {
					alertToast('Bays from the original map data cannot be deleted.', 'error');
					return;
				}
				if (this.isNewBay) {
					await this.getEventHandler().setMapEventState('bay');
				} else if (this.bayEntity) {
					setCustomTag('map-interface', 'delete-a-bay');
					// update the error state on other bays
					await this.updateErrorStateOnAllBaysInArea();
					await this.getController().getTracker().addChange(new DeleteBayCommand(this.bayEntity.id));
				}
				// Emit the toggle confirm button event to hide confirm button from the toolbar
				this.getEventHandler().emit('toggleConfirmCreation', false);
			} else if (event.key === 'Enter' && this.isNewBay) {
				const disableConfirm = this.isDisableConfirm(this.bayEntity); // HITMAT-767
				if (disableConfirm) {
					console.log(`onKeyPress (enter): Confirm disabled. Ignoring.`);
				} else {
					this.onConfirmBay();
				}
			}
		}
	}

	/**
	 * Updates error state of bays in an area
	 * @public
	 */
	public async updateErrorStateOnAllBaysInArea() {
		// This logic relates to the creation of multiple bays
		if (!this.originalBayArea) {
			return;
		}
		// FIXME: serverside validation requests in a loop. This is a massive performance hit.
		// TODO: Call the validateMultiBayErrors instead (checkBayErrors will need to be updated, and the endpoint requires completion)
		const getBayList = this.getController().getMapLookup().getBaysByAreaId(this.originalBayArea.id);
		getBayList?.map(async bay => {
			if (bay.state !== 'IMPORTED') {
				await BayValidator.checkBayErrors(bay, this.mapParams, this.getController());
			}
		});
		if (this.bayEntity.areaId !== this.originalBayArea.id.toString() && !!this.bayEntity.areaId) {
			const _getBayList = this.getController().getMapLookup().getBaysByAreaId(this.bayEntity.areaId);
			_getBayList?.map(async bay => {
				if (bay.state !== 'IMPORTED') {
					await BayValidator.checkBayErrors(bay, this.mapParams, this.getController());
				}
			});
		}
	}

	/**
	 * Confirms the bay
	 * Sets the map event state to 'bay'
	 * Creates the bay
	 * @private
	 */
	private onConfirmBay = (isFromPropertiesPanel: boolean = false) => {
		const eventHandler = this.getEventHandler();
		this.isBayConfirmed = true;
		if (eventHandler.getPreviousState() === 'bay') {
			eventHandler.setMapEventState('bay');
		}

		// If the bay is confirmed by hitting Multi-Bay Creation Add button, it has been created in handleMultiBay()
		// No need to be created here
		if (!isFromPropertiesPanel) {
			// TODO: it would be best to remove this call and add the required logic to onCreateCreate but this may cause unexpected issues.
			// Note that it results in a call to look.createEntity(this.bayEntity) which is duplicated in onTrackCreate. 
			// In all other cases, controller.createEntity() is used only for undo/redo operations (see CreateEntityAction/DeleteEntityAction)
			// this.getController().createEntity(this.bayEntity, this.bayMapObject);
	
			// toggleConfirmCreation event for the confirm button to show/hide
			eventHandler.emit('toggleConfirmCreation', false);
	
			// onTrackCreate event for undo-redo and save
			const importVersion = this.getController().getImportVersion();
			this.bayEntity.importVersionId = importVersion.id;
			// eventHandler.emit('onTrackCreate', this.bayEntity, { bayLocation: {}});

			this.getController().getTracker().addChange(new CreateBayCommand(this.bayEntity));

			setCustomTag('map-interface', 'confirm-a-bay-with-confirm-btn');
		}
		eventHandler.emit('onPropertiesPanel', 'map');
	}

	/**
	 * When exiting handler, remove unconfirmed bay, disable rotate handles,
	 * an unhighlight bay. If transitioning to selector tool, do not unhighlight
	 * as the current object is the one selected via layers.
	 */
	async dispose() {
		// Remove the handlers and reset the cursor
		this.bayMapObject?.disableHandles();
		this.getController().setDefaultCursor();

		if (this.isNewBay && !this.isBayConfirmed) {
			// bay validation
			this.getController().removeMapObject(this.bayEntity, this.bayMapObject);
			this.getLookup().deleteEntity(this.bayEntity, BayEntity);
			// calling this to update the overall error count and also to delete any errors that have been associated with this bay
			// when a bay is removed, the errors associated to it should also be removed from the db
			await BayValidator.checkBayErrors(this.bayEntity, this.mapParams, this.getController(), true);
		} else if (!this.getEventHandler().getToSelector()) {
			this.getController().unhighlightObject();
		}

		this.getRenderer().rerender();

		this.getEventHandler().removeListener('onConfirmMapObjectCreation', this.onConfirmBay);
	}

	/**
	 * Called whe bay location/heading is updated via mouse movement
	 * @param coordinates
	 */
	private updateBay(coordinates: Coordinates.RealWorldCoordinates) {
		if (this.isDraggingCross) {
			BayEditHandler.setBayLocation(this.bayEntity, coordinates);
		} else if (this.isDraggingCorner) {
			BayEditHandler.setBayHeading(
				this.bayEntity,
				coordinates,
				this.originalCursorHeading,
				this.originalBayHeading,
			);
		}

		BayEditHandler.updateBayMapObject(this.bayMapObject, this.getRenderer());
		this.getEventHandler().emit('onPropertiesPanel', 'bay', this.bayEntity);
		if (this.isBayConfirmed) {
			this.getController().getTracker().addChange(new UpdateBayCommand(this.bayEntity));
		}
	}

	/**
	 * Get the bay location in RealWorldCoordinates
	 * @returns
	 */
	private getBayLocation() {
		return geoJsonPointToRealWorldCoords(this.bayEntity.bayLocation);
	}

	/**
	 * Mark and rerender bay map object. Used to dynamically reflect changes on map
	 * when bay heading/location are updated
	 * @param bayObject
	 * @param renderer
	 * @returns
	 */
	public static async updateBayMapObject(bayObject: Bay, renderer: MapRenderer) {
		if (!bayObject) {
			return;
		}

		renderer.markObjectToRerender(bayObject.getId());

		renderer.rerender();
	}

	/**
	 * Check whether a bay is snapped to an eligible node or not by checking if it is snappable
	 * and if heading and location are the same
	 * @param bay
	 * @param snapParams
	 * @returns
	 */
	public static isBaySnapped(bay: BayEntity, snapParams: IBaySnapParams) {
		const isHeadingEqual = bay.heading === snapParams.newAngle;
		let isLocationEqual = false;
		if (snapParams?.isSnappable && snapParams.targetNodeCoords) {
			const snappedBayLocation = BayEditHandler.getSnappedBayLocation(snapParams.targetNodeCoords, snapParams.targetNodeIsStart!, bay.heading, snapParams.offset);
			isLocationEqual = bay.bayLocation === JSON.stringify(realWordCoordsToGeoJSONPoint(snappedBayLocation));
		}
		return snapParams.isSnappable && isHeadingEqual && isLocationEqual;
	}

	@action
	public static setBaySpotdir(bay: BayEntity, snapParams: IBaySnapParams) {
		if (snapParams.isSnappable && !!snapParams.previousNode) {
			if (snapParams.targetNodeTask === 'PARKING') {
					bay.spotDir = snapParams.previousNode!.speed > 0 ? 'DRIVETHROUGH' : 'BACKIN';
			}
		}
	}

	public static getSnappedBayLocation(targetNodeCoords: Coordinates.RealWorldCoordinates, targetNodeIsStart: boolean, bayHeading: number, offset: number) {
		let offsetAlongTarget = offsetAlongHeadingRealWorld(offset, bayHeading);
		const snapBayLocation =  {
			northing: targetNodeCoords.northing + offsetAlongTarget.northing,
			easting: targetNodeCoords.easting + offsetAlongTarget.easting,
		};
		return snapBayLocation;
	}

	@action
	public static setBayLocation(bay: BayEntity, coordinates: Coordinates.RealWorldCoordinates, snapParams?: IBaySnapParams) {
		let coords = coordinates;
		if (snapParams?.isSnappable && snapParams.targetNodeCoords) {
			coords = BayEditHandler.getSnappedBayLocation(snapParams.targetNodeCoords, snapParams.targetNodeIsStart!, bay.heading, snapParams.offset);
		}
		bay.bayLocation = JSON.stringify(realWordCoordsToGeoJSONPoint(coords));
	}

	/**
	 *
	 * @param bay
	 * @param mouseCoordinates
	 * @param originalMouseAngle
	 * @param originalBayHeading
	 * @returns
	 */
	@action
	public static setBayHeading(
		bay: BayEntity,
		mouseCoordinates?: Coordinates.RealWorldCoordinates,
		originalMouseAngle?: number,
		originalBayHeading?: number,
	) {
		if (!mouseCoordinates || !bay.bayLocation) {
			bay.heading = 0;
			return;
		}

		const { coordinates } = getJsonObject(bay.bayLocation);
		const bayCoordinates = Coordinates.realWorldCoordinates(coordinates[1], coordinates[0]);

		const newAngle = BayEditHandler.getAngleBetweenPoints(bayCoordinates, mouseCoordinates);

		bay.heading = originalMouseAngle === undefined || originalBayHeading === undefined
			? newAngle
			: (newAngle - originalMouseAngle + originalBayHeading + 360) % 360;
	}

	/**
	 * TODO: refactor as this has same functionality as calcHeading
	 * @param pointA
	 * @param pointB
	 * @returns
	 */
	public static getAngleBetweenPoints(
		pointA: Coordinates.RealWorldCoordinates,
		pointB: Coordinates.RealWorldCoordinates,
	) {
		const deltaY = -pointA.northing + pointB.northing;
		const deltaX = pointA.easting - pointB.easting;
		const radians = Math.atan2(deltaY, deltaX);
		const degrees = ((radians * 180) / Math.PI + 270) % 360;

		return degrees;
	}

	@action
	public static isSnappableAndSetBayHeading(bay: BayEntity, controller: MapController, coords: Coordinates.RealWorldCoordinates, updateHeading: boolean): IBaySnapParams {
		const bayOffset = controller.getImportVersion().maptoolparam?.truckOffset ?? SNAPPED_DISTANCE;

		const snapParams: IBaySnapParams = {
			isSnappable: false,
			targetNodeIsStart: undefined,
			targetNodeCoords: undefined,
			targetNodeTask: 'NONE',
			newAngle: undefined,
			offset: bayOffset
		};
		const lookup = controller.getMapLookup();

		if (bay.bayType === 'PARKING' || bay.bayType === 'FUELLING' || bay.bayType === 'DUMPCRUSHER') {
			const targetNodeEntity = BayEditHandler.getBayNearestEligibleNode(bay, coords, VICINITY_RADIUS, controller);

			if (targetNodeEntity === undefined) {
				console.log('Target node not found for bay snap');
				return snapParams;
			}

			// Target node is end node having a previous node
			if (!!targetNodeEntity.previousNodeId) {
				const previousNode = lookup.getEntity(targetNodeEntity.previousNodeId, NodeEntity);
				if (previousNode === undefined) {
					console.log('Previous node not found');
					return snapParams;
				}
				snapParams.previousNode = previousNode;
				const fromPoint = {
					easting: previousNode.easting,
					northing: previousNode.northing,
				};
				snapParams.targetNodeCoords = {
					easting: targetNodeEntity.easting,
					northing: targetNodeEntity.northing,
				};
				const _newAngle = BayEditHandler.getAngleBetweenPoints(fromPoint, snapParams.targetNodeCoords);
				// Update bay heading according to path direction
				const newAngle = previousNode.speed > 0 ? _newAngle : (_newAngle + 180) % 360;
				if (updateHeading) {
					bay.heading = newAngle;
				}
				snapParams.isSnappable = true;
				snapParams.targetNodeIsStart = false;
				snapParams.targetNodeTask = targetNodeEntity.task;
				snapParams.newAngle = newAngle;
				return snapParams;
			}

			// Target node is start node having a next node
			if (!!targetNodeEntity.nextNode) {
				const nextNode = lookup.getEntity(targetNodeEntity.nextNode.id, NodeEntity);
				if (nextNode === undefined) {
					console.error('Lookup of nextNode id failed');
					return snapParams;
				}
				snapParams.nextNode = nextNode;
				const toPoint = {
					easting: nextNode.easting,
					northing: nextNode.northing,
				};
				snapParams.targetNodeCoords = {
					easting: targetNodeEntity.easting,
					northing: targetNodeEntity.northing,
				};
				const _newAngle = BayEditHandler.getAngleBetweenPoints(snapParams.targetNodeCoords, toPoint);
				// Update bay heading according to path direction
				const newAngle = nextNode.speed > 0 ? _newAngle : (_newAngle + 180) % 360;
				if (updateHeading) {
					bay.heading = newAngle;
				}
				snapParams.isSnappable = true;
				snapParams.targetNodeIsStart = true;
				snapParams.targetNodeTask = targetNodeEntity.task;
				snapParams.newAngle = newAngle;
				return snapParams;
			}
		}

		return snapParams;
	}

	private static getBayNearestEligibleNode(bay: BayEntity, coords: Coordinates.RealWorldCoordinates, radius: number, controller: MapController): NodeEntity | undefined {
		let nearest: NodeEntity | undefined;

		const lookup = controller.getMapLookup();
		// using record to avoid dups
		const inVicinity: Record<string, { n: NodeEntity, distance: number }> = {};

		const nodes = lookup.getAllEntities(NodeEntity)
			.filter(n => {
				if (bay.bayType === 'PARKING' || bay.bayType === 'FUELLING') {
					return n.task === 'PARKING';
				}
				if (bay.bayType === 'DUMPCRUSHER') {
					return n.task === 'DUMPINGCRUSHER';
				}
				return false;
			});

		nodes.forEach(n => {
			// When a link is removed, only remove link, start sublink, and start node from MapLookup
			// Hence, need to check if the link of the node has been deleted
			if (n.sublinkId) {
				const sublink = lookup.getEntity(n.sublinkId, SublinkEntity);
				if (sublink?.linkId) {
					const link = lookup.getEntity(sublink.linkId, LinkEntity);
					if (!!link) {
						const distance = Math.sqrt((n.easting - coords.easting) ** 2 + (n.northing - coords.northing) ** 2);
						if (distance <= radius) {
							inVicinity[n.nodeId.toString()] = { n, distance };
						}
					}
				}
			}
		});

		const found = Object.values(inVicinity);

		if (found.length === 0) {
			// console.log('No eligible nodes in vincinity. Ignore.');
		} else {
			nearest = found.sort((a, b) => a.distance - b.distance)[0].n;
		}

		return nearest;
	}
}
