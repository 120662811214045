import MapStateHandler from "../MapStateHandler";
import {LeafletMouseEvent} from "leaflet";
import {LeafletCoordinates} from "../../Helpers/Coordinates";
import PathManager from "./Helpers/PathManager";
import {HoverState} from "../../MapStateHandlerHelpers/PathToolHelper";
import {calcHeading} from "../../Helpers/MapUtils";
import {isCtrlKeyHeld, isShiftKeyHeld} from "../MapGlobalEventHandler";

type PathEditHandlerParams = PathManager | undefined;

export default class PathEditHandler extends MapStateHandler<PathEditHandlerParams> {

	private state: PathManager;

	private ignoreDispose: boolean = false;

	private hoverState = HoverState.DEFAULT;
	private hoveringWaypointId: string | undefined = undefined;

	onInit<T>(initialState: T): Promise<void> | void {
		this.state = initialState !== undefined && initialState instanceof PathManager
			? initialState as PathManager : new PathManager(this);

		this.state.init();
	}

	onMove(event: LeafletMouseEvent): Promise<void> | void {
		[this.hoverState, this.hoveringWaypointId] = this.state.renderHelper.isHoveringOverWaypoint(event.latlng);

		if (this.hoverState === HoverState.WAYPOINT) {
			this.state.renderHelper.setMoveCursor();
		} else if (this.hoverState == HoverState.HEADING_BACK || this.hoverState == HoverState.HEADING_FRONT) {
			this.state.renderHelper.setRotateCursor();
		} else {
			this.state.renderHelper.setDefaultCursor();
		}
	}

	onDoubleClick(event: LeafletMouseEvent): Promise<void> | void {
		// Create/Delete a mid waypoint if clicking on a node
		const tryAddMidwaypoint = this.state.tryToggleMidWaypoint(event.latlng, () => this.state.stopAction());

		if (!tryAddMidwaypoint) {
			// If clicking outside the nodes, confirm path
			this.onConfirm();
		}
	}

	onDragStart(event: LeafletMouseEvent, originalCoordinates: LeafletCoordinates): Promise<void> | void {
	}

	onDragMove(event: LeafletMouseEvent): Promise<void> | void {
		if (this.hoverState === HoverState.DEFAULT || !this.hoveringWaypointId) {
			return;
		}

		const waypoint = this.state.waypoints.getWaypoint(this.hoveringWaypointId);
		if (!waypoint) {
			return;
		}

		// Remove the snap if needed
		this.state.waypoints.unSnapWaypoint(waypoint.id);

		const coords = this.getRenderer().getRealWorldCoords(event.latlng);
		const isStraightOperation = isShiftKeyHeld(event.originalEvent);

		if (this.hoverState === HoverState.WAYPOINT) {
			const previousWaypoint = isStraightOperation ? this.state.waypoints.getWaypointBefore(this.hoveringWaypointId) : undefined;
			if (isStraightOperation && previousWaypoint) {
				// Set the same heading
				waypoint.setHeading(previousWaypoint.heading);
				waypoint.coordinates = this.state
					.getStraightWaypointLocation(previousWaypoint.coordinates, coords, previousWaypoint.heading);
			} else {
				waypoint.coordinates = coords;
			}
		} else if (this.hoverState === HoverState.HEADING_FRONT) {
			waypoint.setHeading(calcHeading(waypoint.coordinates, coords));
		} else if (this.hoverState === HoverState.HEADING_BACK) {
			// When we are moving the back heading handle, we want to reverse the direction to get the correct heading
			waypoint.setHeading(calcHeading(coords, waypoint.coordinates));
		}

		this.state.processPathUpdate();
	}

	onDragEnd(event: LeafletMouseEvent): Promise<void> | void {
		const coords = this.getRenderer().getRealWorldCoords(event.latlng);
		const isSnapOperation = isCtrlKeyHeld(event.originalEvent);
		const isStraightOperation = isShiftKeyHeld(event.originalEvent);

		if (isSnapOperation && isStraightOperation || !this.hoveringWaypointId) {
			this.state.stopAction();
			return;
		}

		const waypoint = this.state.waypoints.getWaypoint(this.hoveringWaypointId);
		if (waypoint && isSnapOperation) {
			const snapOption = this.state.snapHelper.checkForSnap(coords, waypoint);
			if (snapOption) {
				waypoint.shortClicked = false;
				waypoint.setHeading(snapOption.targetHeading);
				waypoint.coordinates = snapOption.targetLocation;

				this.state.waypoints.handleSnapOption(snapOption);
			}
		}

		this.hoveringWaypointId = undefined;

		this.state.stopAction();
	}

	onKeyPress(event: KeyboardEvent): Promise<void> | void {
		if (this.getEventHandler().isEnter(event)) {
			this.onConfirm();
		} else if (this.getEventHandler().isBackspace(event)) {
			this.state.cancelPath();
			this.transitionToCreateMode();
		}

		const { key } = event;
		if (key === 'B' && !this.state.waypoints.isEndSnapped())  {
			this.transitionToCreateMode();
		}
	}

	onEscapePressed(event: KeyboardEvent): Promise<void> | void {
		if (this.state.waypoints.hasWaypoint()) {
			this.state.cancelPath();
			this.setIgnoreDispose();
			this.getEventHandler().setMapEventState('path', this.state);
		} else {
			this.getEventHandler().setActiveTool('selector');
		}
	}

	onRequestUpdate(entity?: unknown): Promise<void> | void {
		// Process any inputs from the properties panel
		this.state.processPathUpdate();
	}

	onConfirm(): Promise<void> | void {
		if (this.state.confirmPath()) {
			this.state.reset();

			this.getEventHandler().setActiveTool('path');
		}
	}

	dispose(): Promise<void> | void {
		if (this.ignoreDispose) {
			return;
		}

		this.state.dispose();
	}

	private setIgnoreDispose() {
		this.ignoreDispose = true;
	}

	private transitionToCreateMode() {
		// Move out of build mode
		this.state.setCreating();

		this.state.renderHelper.setDefaultCursor();

		this.setIgnoreDispose(); // Transitioning to the next state should ignore disposal

		this.getEventHandler().setMapEventState('path', this.state);
	}
}