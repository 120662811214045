import {WaypointList} from "./Waypoint";
import axios, {AxiosError} from "axios";
import {PATH_ERROR_NODE_LIMIT, SERVER_URL} from "../../../../../../Constants";
import {store} from "../../../../../../Models/Store";
import {MapController} from "../../../../index";
import {LinkEntity, LinkFromLinkTo, NodeEntity, SublinkEntity} from "../../../../../../Models/Entities";
import {
	AnotherLinkNodeErrorResult,
	MapErrorFlags, MapWarningFlags,
	NodeErrorResult,
	SublinkErrorResult
} from "../../../ServerValidation";
import alertToast from "../../../../../../Util/ToastifyUtils";
import PathPropertiesPanelHelper from "./PathPropertiesPanelHelper";
import PathValidationHelper from "./PathValidationHelper";
import PathToolHelper from "../../../MapStateHandlerHelpers/PathToolHelper";
import * as uuid from "uuid";
import MapStore from "../../../MapStore";

interface IClothoidResponse {
	link: LinkEntity;
	linkErrors: MapErrorFlags[];
	sublinkErrors: SublinkErrorResult[];
	nodeErrors: NodeErrorResult[];
	otherLinkNodeErrors: AnotherLinkNodeErrorResult[];
	otherLinks: LinkEntity[];
}

interface IDrivingZoneUpdate {
	tempLinkFroms: LinkFromLinkTo[],
	tempLinkTos: LinkFromLinkTo[],
	tempLinkId?: string,
	linkEntities: object[],
	startEndLinks: object[],
	exitPathIds: string[],
	entryPathIds: string[],
}

export default class PathGenerationHelper {
	private abortController: AbortController;

	private mapController: MapController;
	public propertiesHelper: PathPropertiesPanelHelper;
	public validationHelper: PathValidationHelper;
	private lastSuccessfulWaypoints: WaypointList | undefined;

	public requestInProgress = false;
	public hasErrored = false;

	constructor(mapController: MapController,
				propertiesHelper: PathPropertiesPanelHelper,
				validationHelper: PathValidationHelper) {
		this.mapController = mapController;
		this.propertiesHelper = propertiesHelper;
		this.validationHelper = validationHelper;
	}

	public dispose() {
		this.lastSuccessfulWaypoints = undefined;
	}

	public isRequestInProgress() {
		return this.requestInProgress;
	}

	public restoreLastSuccessfulWaypoints(waypoints: WaypointList) {
		const hasErrored = this.hasErrored;
		this.hasErrored = false;

		// If it is not a full path, we want to reset the last successful waypoints
		if (!waypoints.isFullPath()) {
			this.lastSuccessfulWaypoints = undefined;
		}

		// If we didn't have an error or waypoints to reset to, return that we haven't reset anything
		if (!hasErrored || !this.lastSuccessfulWaypoints) {
			return false;
		}

		waypoints.setWaypoints(this.lastSuccessfulWaypoints);
		return true;
	}

	private getDrivingZoneUpdates(waypoints: WaypointList): IDrivingZoneUpdate {
		const mapStore = this.mapController.getMapLookup();
		const tempId = uuid.v4();

		/*****
		 * Removed until the driving zone specifications are finalized
		 */

		// const linkFroms = waypoints.firstWaypoint?.connections.map(x => new LinkFromLinkTo({
		// 	linkFromId: x,
		// 	linkToId: tempId,
		// })) ?? [];
		// const linkTos = !waypoints.isFullPath() ? []
		// 	: waypoints.lastWaypoint.connections.map(x => new LinkFromLinkTo({
		// 			linkFromId: tempId,
		// 			linkToId: x,
		// 		}));
		//
		// // Create a temporary active link that can be traversed for the driving zone area of affect calculation
		// const activeLink = new LinkEntity({ id: tempId,
		// 	sublinkss: [new SublinkEntity({
		// 		nodess: waypoints.iter.map((x, i, nodess) => x.toNode(i === nodess.length - 1))
		// 	})],
		// });
		//
		// // @ts-ignore
		// const affectedLinkTos = linkTos.flatMap(x => getLinksToRecalculateDrivingZone(x, activeLink));
		// // @ts-ignore
		// const affectedLinkFroms = linkFroms.flatMap(x => getLinksToRecalculateDrivingZone(x, activeLink));
		// const affectedLinks: LinkEntity[] = affectedLinkTos.concat(affectedLinkFroms);
		//
		// // Are any of the paths entry or exit paths for areas?
		// const exitPathIds = PathToolHelper.getExitPathIds(affectedLinks);
		// const entryPathIds = PathToolHelper.getEntryPathIds(affectedLinks);


		// const startEndLinks = affectedLinks.reduce<LinkEntity[]>((links, linkEntity) => {
		// 	linkEntity.linkFroms.forEach(linkFrom => {
		// 		const previousLink = affectedLinks!.find(x => x.id === linkFrom.linkFromId);
		// 		if (!previousLink) {
		// 			const isActiveLink  = linkFrom.linkFromId === activeLink?.id;
		// 			const newLink = isActiveLink ? activeLink : mapStore.getEntity(linkFrom.linkFromId, LinkEntity);
		// 			if (!!newLink) {
		// 				links.push(newLink);
		// 			}
		// 		}
		// 	});
		// 	linkEntity.linkTos.forEach(linkTo => {
		// 		const nextLinks = affectedLinks!.find(x => x.id === linkTo.linkToId);
		// 		if (!nextLinks) {
		// 			const isActiveLink = linkTo.linkToId === activeLink?.id;
		// 			const newLink = isActiveLink ? activeLink : mapStore.getEntity(linkTo.linkToId, LinkEntity);
		// 			if (!!newLink) {
		// 				links.push(newLink);
		// 			}
		// 		}
		// 	});
		//
		// 	return links;
		// }, []);
		//
		// const startEndLinksJson = _.uniqBy(startEndLinks, x => x.id).map(link => {
		// 	this.normalizeLink(link, mapStore);
		// 	return link.toJSON(linkReferencePath);
		// });
		//
		// const linkEntities = affectedLinks
		// 	.filter(x => x.id !== tempId)
		// 	.map(x => x.toJSON(linkReferencePath));

		return {
			tempLinkId: tempId,
			tempLinkFroms: [], // linkFroms,
			tempLinkTos: [], // linkTos,
			linkEntities: [], // linkEntities,
			startEndLinks: [], // startEndLinksJson,
			exitPathIds: [], // exitPathIds,
			entryPathIds: [], // entryPathIds,
		};
	}

	/**
	 * Sends the waypoints to the server to generate a path. Once complete, the response is processed for errors and
	 * the link data.
	 *
	 * The request can be aborted by calling the abort method on this helper
	 *
	 * @param waypoints list to calculate the path from
	 */
	public async performRequest(waypoints: WaypointList): Promise<IClothoidResponse | null | undefined> {
		if (this.requestInProgress || !waypoints.isFullPath()) {
			return null;
		}

		this.requestInProgress = true;

		this.abortController = new AbortController();
		const signal = this.abortController.signal;

		const lastWaypoints = waypoints.copy();
		const waypointData = lastWaypoints.getRequestData();

		const isEntryPath = PathToolHelper.isEntryPath(waypoints.firstWaypoint!, waypoints.lastWaypoint, this.mapController) !== undefined;
		const isExitPath = PathToolHelper.isExitPath(waypoints.firstWaypoint!, waypoints.lastWaypoint, this.mapController) !== undefined;

		let response: IClothoidResponse | undefined = undefined;
		try {
			const { data } = await axios.post(`${SERVER_URL}/api/entity/LinkEntity/calulateClothoidPath`, {
				waypoints: waypointData,
				importVersionId: this.mapController.getMapLookup().getImportVersion().id,
				directions: waypoints.iter.map(x => x.direction),
				isHCMPGEnabled: store.isHCMPGEnabled,
				isExitPath,
				isEntryPath,
				...this.getDrivingZoneUpdates(waypoints),
			});

			if (!signal.aborted) {
				response = {
					link: new LinkEntity(data.item1),
					linkErrors: data.item2,
					sublinkErrors: data.item3,
					nodeErrors: data.item4,
					otherLinkNodeErrors: data.item5,
					otherLinks: data.item6.map((x: any) => new LinkEntity(x))
				};

				// Add server validation errors to the link
				this.processResponse(response, waypoints);

				// Check for client validation errors
				this.checkForLinkErrors(response);

				// Store the last successful waypoints if we need to restore them later
				this.lastSuccessfulWaypoints = lastWaypoints;
			}
		} catch (e: any) {
			this.handleError(e);
		}

		this.requestInProgress = false;
		return response;
	}

	/**
	 * Add the references to the link, sublinks and nodes to make it easier to work with. As well as re-assign
	 * waypoint values to the corresponding nodes
	 *
	 * @param response from the endpoint
	 * @param waypoints the waypoints used to generate the response
	 */
	private processResponse(response: IClothoidResponse, waypoints: WaypointList) {
		const {
			link,
			linkErrors,
			sublinkErrors,
			nodeErrors
		} = response;

		link.getModelId();

		// Transform each object into an entity
		link.sublinkss = link.sublinkss.map(s => {
			const newSublink = new SublinkEntity(s);
			newSublink.nodess = s.nodess.map(n => new NodeEntity(n));
			return newSublink;
		});

		link.importVersionId = this.mapController.getImportVersion().id;

		// Process the errors and assign them to the corresponding entity
		linkErrors.forEach(x => link.addError(x));
		link.sublinkss.forEach((s, i) => {
			// Update values on the sublink to make processing easier later
			s.link = link;
			s.linkId = link.getModelId();

			// Assign the previous sublink id to the current sublink
			// This is useful for when confirming and saving the path to the database
			if (i !== 0) {
				s.previousSublink = link.sublinkss[i - 1];
				s.previousSublinkId = link.sublinkss[i - 1]?.getModelId();
			}
			if (i !== link.sublinkss.length - 1) {
				s.nextSublink = link.sublinkss[i + 1];
			}

			const sublinkErrorObject = sublinkErrors.find(e => e.index === i);
			sublinkErrorObject?.errors.forEach(e => s.addError(e));
			sublinkErrorObject?.warnings.forEach(w => s.addWarning(w));

			s.nodess.forEach((n, j) => {
				// Need to set the node task of a node based on the waypoint task (this way it can render on the map)
				const waypoint = waypoints.getWaypoint(n.id);
				if (!!waypoint) {
					n.task = waypoint.task;
					n.heading = waypoint.heading;
				}

				// Add the previous node
				if (j !== 0) {
					n.previousNode = s.nodess[j - 1];
					n.previousNodeId = s.nodess[j - 1]?.getModelId();
				}

				// Add the next node
				if (j !== s.nodess.length - 1) {
					n.nextNode = s.nodess[j + 1];
				}

				// Update values on the node to make processing easier later
				n.sublink = s;
				n.sublinkId = s.getModelId();
				n.isMidWaypoint = waypoints.isMidWaypoint(n.id) || n.task !== 'HAULING';

				const nodeErrorObject = nodeErrors.find(e => e.sublinkIndex === i && e.index === j);
				nodeErrorObject?.errors.forEach(e => n.addError(e));
			});
		});
	}

	public triggerAbort(reason?: string) {
		this.abortController?.abort(reason);
	}

	private checkForLinkErrors(response: IClothoidResponse) {
		const { link, linkErrors, sublinkErrors, nodeErrors } = response;

		const errors = this.validationHelper.isPathValid(link);

		let allSublinkErrors: MapErrorFlags[] = [];
		let allSublinkWarnings: MapWarningFlags[] = [];
		
		sublinkErrors.forEach(sublinkError => {
			allSublinkErrors = allSublinkErrors.concat(sublinkError.errors);
			allSublinkWarnings = allSublinkWarnings.concat(sublinkError.warnings);
		});
		
		const hasErrors = errors.length > 0 || linkErrors.length > 0 || allSublinkErrors.length>0 || nodeErrors.length > 0

		this.propertiesHelper.getErrorsAndWarnings().clear();
		if (hasErrors) {
			this.propertiesHelper.disableConfirmButton();

			// Set an error message in the properties panel
			this.propertiesHelper.getErrorsAndWarnings().addErrors(linkErrors);
			this.propertiesHelper.getErrorsAndWarnings().addErrors(allSublinkErrors);
			// @ts-ignore
			this.propertiesHelper.getErrorsAndWarnings().addErrors(nodeErrors.flatMap(x => x.errors));

			this.propertiesHelper.getErrorsAndWarnings().addErrors(errors);

		} else {
			this.propertiesHelper.enableConfirmButton();
		}

		// No need to enable/disable the confirm button if there are warnings
		const hasWarnings = allSublinkWarnings.length > 0 ;
		if (hasWarnings) {
			this.propertiesHelper.getErrorsAndWarnings().addWarnings(allSublinkWarnings);
		}
	}

	private handleError(error: AxiosError) {
		// Only display the error once
		if (!this.hasErrored) {
			// Display an error message
			let msg = error.response?.data?.errors[0]?.message;
			if (!!msg && msg.includes('Exceeded limit of ')) {
				alertToast(PATH_ERROR_NODE_LIMIT, 'error');
			} else {
				console.error("handleError error:", error);
				alertToast(msg ?? 'Clothoid path error', 'error');
			}
		}

		this.hasErrored = true;
	}

	private normalizeLink = (linkEntity: LinkEntity, mapStore: MapStore) => {
		const setPreviousNodeForSublink = (sublink: SublinkEntity) => {
			sublink.nodess.forEach(node => {
				if (node.previousNodeId) {
					const previousNode = mapStore.getEntity(node.previousNodeId, NodeEntity);
					if (!!previousNode) {
						node.previousNode = previousNode;
					}
				}
				if (!!node.nextNode) {
					const nextNode = mapStore.getEntity(node.nextNode.id ?? node.nextNode._clientId, NodeEntity);
					if (!!nextNode) {
						node.nextNode = nextNode;
					}
				}
			});
		};

		linkEntity.sublinkss.forEach(sublink => {
			setPreviousNodeForSublink(sublink);
			const node = mapStore.getFirstNodeForSublink(sublink.id);

			if (node) {
				sublink.nodess = sublink.getNodes();
			}
		});
	};
}