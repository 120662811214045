import {LeafletMouseEvent} from 'leaflet';
import {Link, MapEventHandler, Sublink,} from 'Views/MapComponents';
import {defaultContextMenuOptions, IRightClickContextMenuOptions} from 'Views/MapComponents/RightClickContextMenu';
import {PixiCoordinates} from '../Helpers/Coordinates';
import * as PIXI from 'pixi.js';
import {action} from 'mobx';
import {LinkEntity, LinkFromLinkTo, NodeEntity, SublinkEntity} from 'Models/Entities';
import alertToast from 'Util/ToastifyUtils';
import MapObject from '../MapObjects/MapObject';
import LinkConnectivityDisplayHelper from './LinkConnectivityDisplayHelper';
import {
	ERROR_MAXIMUM_LINK_ID,
	ERROR_MAXIMUM_SUBLINK_ID,
	JOIN_LINK_ALLOWED_RESULT,
	MAX_NODES_PER_LINK,
	MAX_SUBLINKS_PER_LINK
} from '../../../../Constants';
import {store} from '../../../../Models/Store';
import {nodetask} from 'Models/Enums';
import BreakSublinkCommand from "../../ChangeTracker/ChangeTypes/BreakSublinkCommand";
import JoinSublinkCommand from "../../ChangeTracker/ChangeTypes/JoinSublinkCommand";
import BreakLinkCommand from "../../ChangeTracker/ChangeTypes/BreakLinkCommand";
import JoinLinkCommand from "../../ChangeTracker/ChangeTypes/JoinLinkCommand";
import ConfirmedPathSelectHandler from "../MapStateHandlers/PathTool/ConfirmedPathSelectHandler";

const CONNECTIVITY_LINE_WIDTH = 2;

interface ILineSegmentData {
	polygon: PIXI.Polygon;
	index: number;
}

export interface IBreakSublinkParams {
	originalNodes: NodeEntity[];
	splitNodes: NodeEntity[];
	lineSegmentIndex: number;
	nextAvaialbleIdForSublink: number;
	isBreakLinkOperation?: boolean;
	link: Link;
	sublink: Sublink;
}

interface IBreakLinkParams {
	lastNode: NodeEntity;
	firstNodeForNextSublink: NodeEntity | undefined;
	orderedSublinks: SublinkEntity[];
	nextAvaialbleIdForLink: number;
	sublinkIndex: number;
	link: Link;
	segmentIndex: number;
}

function  getObjectStateFromImportStatus(isImported: boolean) {
	return isImported ? 'MODIFIED' : 'NEW_OBJECT';
}

export function updateLinkSublinkConnectivityState(link: LinkEntity, isStartNodeLink: boolean) {
	if (link.isImported || link.sublinkss.some(s => s.isImported)) {
		link.state = getObjectStateFromImportStatus(link.isImported);
		let sublink;
		if (isStartNodeLink) {
			sublink = link.firstSublink();
		} else {
			sublink = link.lastSublink();
		}
		if (sublink) {
			sublink.state = getObjectStateFromImportStatus(sublink.isImported);
		}
	}
}

export function updateLinkSublinkNodeStates(link: LinkEntity, eventHandler?: MapEventHandler) {
	link.state = getObjectStateFromImportStatus(link.isImported);
	link.sublinkss.forEach(sublink => {
		sublink.state = getObjectStateFromImportStatus(sublink.isImported);
		sublink.nodess.forEach(node => {
			node.state = getObjectStateFromImportStatus(node.isImported);
		});
	});
}

export function updateLinkStateOnly(link: LinkEntity, eventHandler?: MapEventHandler) {
	link.state = getObjectStateFromImportStatus(link.isImported);
	console.log(`link: state changed to ${link.state}`);
}

export function updateLinkSublinkStatesOnly(link: LinkEntity) {
	link.state = getObjectStateFromImportStatus(link.isImported);
	link.sublinkss.forEach(sublink => {
		sublink.state = getObjectStateFromImportStatus(sublink.isImported);
	});
	console.log(`link: state changed to ${link.state}`);
}

export default class LinkOperationsHelper {
	public static async linkRightClickMenu(event: LeafletMouseEvent,
		eventHandler: MapEventHandler,
		link: Link): Promise<boolean> {
		const coords = eventHandler.getRenderer().project(event.latlng);
		const sublink = eventHandler.getController().getMapObjectAtCoordinates(coords, ['sublink']) as Sublink;
		const breakSublinkParams = !!sublink
			? this.getBreakSublinkParams(eventHandler, coords, link, sublink)
			: undefined;
		if (!!sublink && !breakSublinkParams) {
			console.log('Unable to break sublink');
			eventHandler.emit('onCustomContextMenu', defaultContextMenuOptions, event);
			return false;
		}

		const menuOptions: IRightClickContextMenuOptions[] = [
			{
				text: 'Break link',
				disabled: false,
				separator: false,
				onClick: () => {
					this.breakLink(eventHandler, coords, link);
				},
			},
		];

		/**
		 * Build other menu options.
		 * These options are displayed based on the 'breakSublinkParams' condition.
		 */
		const buildBreakSublinkOption = {
			text: 'Break sublink',
			disabled: false,
			separator: false,
			onClick: () => {
				if (breakSublinkParams) {
					this.breakSublink(eventHandler, breakSublinkParams);
				}
			},
		};

		const buildJoinSublinkOption = {
			text: 'Join sublink',
			disabled: false,
			separator: false,
			onClick: () => this.joinSublink(eventHandler, coords, link),
		};

		if (!!breakSublinkParams) {
			// valid sublink params implies that break sublink is possible, hence create menu item
			menuOptions.push(buildBreakSublinkOption);
		}

		if (!breakSublinkParams) {
			const isTwoNodesSameTask = this.checkNodesTasksToJoinSublinks(eventHandler, coords, link);
			if (isTwoNodesSameTask) {
				menuOptions.push(buildJoinSublinkOption);
			}
		}

		eventHandler.emit('onCustomContextMenu', menuOptions, event);
		return true;
	}

	public static connectivityRightClickMenu(event: LeafletMouseEvent, eventHandler: MapEventHandler,
		connectivityNodes: {fromLinkEndNode: NodeEntity, toLinkEndNode: NodeEntity}) {

		const menuOptions: IRightClickContextMenuOptions[] = [
			{
				text: 'Join link',
				disabled: false,
				separator: false,
				onClick: () => this.joinLinks(eventHandler, connectivityNodes),
			},
		];
		eventHandler.emit('onCustomContextMenu',
			menuOptions,
			event);
	}

	/**
	 * Handle right click for connectivity
	 * Shows the right click context menu
	 * based on the validity
	 * Shows default option if not valid
	 * @param event
	 * @param mapObject
	 * @param linkConnectivityDisplayHelper
	 * @param eventHandler
	 * @returns true if the connectivity menu is displayed
	 */
	public static handleConnectivityRightClick = (event: LeafletMouseEvent,
		mapObject: MapObject<unknown>,
		linkConnectivityDisplayHelper: LinkConnectivityDisplayHelper,
		eventHandler: MapEventHandler): boolean => {
		const {
			connectivityToNode, endNodeForSelectedLink, connectivityFromNode, startNodeForSelectedLink,
		} = linkConnectivityDisplayHelper;

		let isContextMenuDisplayed = this.displayContextMenu(
			event,
			connectivityToNode,
			endNodeForSelectedLink,
			mapObject,
			false,
			eventHandler,
		);

		if (!isContextMenuDisplayed) {
			isContextMenuDisplayed = this.displayContextMenu(
				event,
				connectivityFromNode,
				startNodeForSelectedLink,
				mapObject,
				true,
				eventHandler,
			);
		}

		if (!isContextMenuDisplayed) {
			eventHandler.emit('onCustomContextMenu', defaultContextMenuOptions, event);
		}
		return isContextMenuDisplayed;
	}

	public static isForbiddenLinkJoinNodes(node1: NodeEntity, node2: NodeEntity): boolean {
		const forbiddenTasks: nodetask[] = ['PARKING', 'DUMPINGCRUSHER'];
		return forbiddenTasks.includes(node1.task) || forbiddenTasks.includes(node2.task);
	}

	/**
	 * Display the context menu only if it is valid
	 * @param event
	 * @param otherLinkConnectivityNode
	 * @param currentLinkConnectivityNode
	 * @param mapObject
	 * @param isCurrentLinkNodeStartNode
	 * @param eventHandler
	 * @constructor
	 */
	private static displayContextMenu = (event: LeafletMouseEvent,
		otherNodeEntity: NodeEntity | undefined,
		currentNodeEntity: NodeEntity | undefined,
		mapObject: MapObject<unknown>,
		isCurrentLinkNodeStartNode: boolean,
		eventHandler: MapEventHandler): boolean => {
		/**
		 * Proceed only if both the connectivity nodes exist
		 */
		if (!currentNodeEntity || !otherNodeEntity) {
			return false;
		}
		/**
		 * Get the coordinates for the both the connectivity nodes
		 * to generate the hit area (polygon) for validity test
		 */
		const otherNodeCoords = eventHandler.getRenderer().project(otherNodeEntity);
		const currentNodeCoords = eventHandler.getRenderer().project(currentNodeEntity);
		/**
		 * Generate the hit area (polygon) for these points
		 */
		const points = [otherNodeCoords, currentNodeCoords];
		const connectivityPolygon = new PIXI.Polygon(mapObject
			.generateHitAreaFromLine(points, CONNECTIVITY_LINE_WIDTH));
		/**
		 * Get the coordinates for the right clicked point
		 * and perform a check if the user right clicked on
		 * the hit area (polygon)
		 */
		const targetCoords = eventHandler.getRenderer().project(event.latlng);
		const isClickedOnPolygon = connectivityPolygon.contains(targetCoords.x, targetCoords.y);

		if (!isClickedOnPolygon) {
			return false; 
		}
		/**
		 * Show appropriate context menu options if right click is on the polygon.
		 * Return true if so; otherwise return false.
		 */
		const connectivityEndNodes = {
			fromLinkEndNode: isCurrentLinkNodeStartNode ? otherNodeEntity : currentNodeEntity,
			toLinkEndNode: isCurrentLinkNodeStartNode ? currentNodeEntity : otherNodeEntity,
		};
		if (this.isForbiddenLinkJoinNodes(connectivityEndNodes.toLinkEndNode, connectivityEndNodes.fromLinkEndNode)) {
			console.log("Forbidden from joining links due to task");
			return false;
		} else {
			LinkOperationsHelper.connectivityRightClickMenu(event, eventHandler, connectivityEndNodes);
		}
		return true;
	}

	/**
	 * Validate if these links are allowed to be connected
	 */
	public static isJoinLinkActionAllowed = (connectivityNodes: {fromLinkEndNode: NodeEntity, toLinkEndNode: NodeEntity}): JOIN_LINK_ALLOWED_RESULT => {
		const { fromLinkEndNode, toLinkEndNode } = connectivityNodes;

		const fromLink = fromLinkEndNode.getLink();
		const toLink = toLinkEndNode.getLink();

		const fromNodes = fromLink?.getNodes() ?? [];
		const toNodes = toLink?.getNodes() ?? [];

		const fromSublinks = fromLink?.getSublinks() ?? [];
		const toSublinks = toLink?.getSublinks() ?? [];

		if (fromSublinks.length + toSublinks.length > MAX_SUBLINKS_PER_LINK) {
			return JOIN_LINK_ALLOWED_RESULT.MaxSublinksExceeded;
		}
		if (fromNodes.length + toNodes.length > MAX_NODES_PER_LINK) {
			return JOIN_LINK_ALLOWED_RESULT.MaxNodesExceeded;
		}
		
		return JOIN_LINK_ALLOWED_RESULT.Allowed;
	};

	/**
	 * Process data, set refs and re-render link after join link operation.
	 * Saving data hands over to onTrackJoinLink.
	 * @param eventHandler
	 * @param connectivityNodes
	 */
	private static joinLinks = (eventHandler: MapEventHandler,
		connectivityNodes: {fromLinkEndNode: NodeEntity, toLinkEndNode: NodeEntity}) => {

		const errorMessage = 'Join link action not allowed. Links cannot contain more than';
		const lookup = eventHandler.getLookup();

		// Check if this join action is allowed
		switch (this.isJoinLinkActionAllowed(connectivityNodes)) {
			case JOIN_LINK_ALLOWED_RESULT.MaxNodesExceeded:
				alertToast(`${errorMessage} ${MAX_NODES_PER_LINK} nodes`, 'error');
				return;
			case JOIN_LINK_ALLOWED_RESULT.MaxSublinksExceeded:
				alertToast(`${errorMessage} ${MAX_SUBLINKS_PER_LINK} sublinks`, 'error');
				return;
		}

		// Get necessary instances for the operation. i.e. stateHandler, linkHandler.
		const stateHandler = eventHandler.getStateHandler();
		const linkHandler = stateHandler instanceof ConfirmedPathSelectHandler ? stateHandler : undefined;

		if (!linkHandler) {
			console.error('link handler undefined');
			return;
		}
		// Hide connectivity graphic before rendering it again after the join operation
		linkHandler.getLinkConnectivityDisplayHelper().hideConnectivity();

		// Get links for the given connectivity nodes. Proceed only if the links exist.
		const fromLink = lookup.getLinkByIdNumber(connectivityNodes.fromLinkEndNode.linkIdNumber);

		// These operations must be performed becore copyLink to avoid data integrity issues
		fromLink?.orderSublinksAndNodes();
		fromLink?.resetSublinkAndNodeRefs(true);

		const toLink = eventHandler.getLookup().getLinkByIdNumber(connectivityNodes.toLinkEndNode.linkIdNumber);

		if (!fromLink || !toLink) {
			console.error('Unable to find links')
			return;
		}

		eventHandler.getController().getTracker()
			.addChange(new JoinLinkCommand(fromLink.id, toLink.id));
	}

	/**
	 * Resets the speed of nodes in the provided links/sublinks based on the link direction.
	 * If the node speed is positive, it is set to the provided forward speed.
	 * If the node speed is negative, it is set to the provided reverse speed.
	 *
	 * @param links
	 * @param forwardSpeed - The speed value to be set for nodes with positive speed.
	 * @param reverseSpeed - The speed value to be set for nodes with negative speed.
	 */
	private static resetNodeSpeeds(links: LinkEntity[], forwardSpeed : number, reverseSpeed : number) {
		links.forEach(link => {
			link.sublinkss.forEach(subLink => {
				// based on link direction, set node speed to the default speed as defined in map params
				const nodes = subLink.getNodes();
				nodes.forEach(node => {
					if (node.isSpecialTask()) {
						node.speed = 0;
					} else if (node.speed > 0) {
						node.speed = forwardSpeed; 
					} else if (node.speed < 0) {
						node.speed = reverseSpeed;
					}
				});
			});
		});
	}

	/**
	 * Process data, set refs and re-render link after join sublink operation.
	 * Saving data hands over to onTrackJoinSublink.
	 * @param eventHandler
	 * @param coords
	 * @param link
	 */
	@action
	private static joinSublink = async (eventHandler: MapEventHandler, coords: PixiCoordinates, link: Link) => {
		/**
		 * Link handler is a pre-requisite for the operation
		 */
		const stateHandler = eventHandler.getStateHandler();
		const linkHandler = stateHandler instanceof ConfirmedPathSelectHandler ? stateHandler : undefined;
		if (!linkHandler) {
			return;
		}

		/**
		 * Get the target line segment to find out the indices of the
		 * nodes on the edge for the previous and next sublinks
		 */
		const targetLineSegment = this.hittestByLineSegment(link, coords);
		if (!targetLineSegment) {
			return;
		}

		eventHandler.getController().getTracker()
			.addChange(new JoinSublinkCommand(link.getLinkEntity().id, targetLineSegment.index));
	}

	/**
	 * Hittest a link/sublink to find particular line segment selected
	 * @param linkOrSublink
	 * @param targetCoords
	 * @returns
	 */
	public static hittestByLineSegment(
		linkOrSublink: Sublink | Link,
		targetCoords: PixiCoordinates
	): ILineSegmentData | undefined {
		const points = linkOrSublink.getEntity();
		const { x, y } = targetCoords;
		return points.slice(0, -1).map((point, index): ILineSegmentData => {
			const nextPoint = points[index + 1];
			return {
				polygon: new PIXI.Polygon(linkOrSublink.generateHitAreaFromLine([point, nextPoint], 2)),
				index: index,
			};
		}).find(s => s.polygon.contains(x, y));
	}

	public static getBreakLinkBetweenSublinksParams(
		eventHandler: MapEventHandler,
		coords: PixiCoordinates,
		link: Link
	): IBreakLinkParams | undefined {
		// between sublinks
		const targetLineSegment = this.hittestByLineSegment(link, coords);
		if (!targetLineSegment) {
			return undefined;
		}
		const lookup = eventHandler.getLookup();
		const linkEntity = link.getLinkEntity();
		const sublinkEntityId = lookup.getFirstSublinkForLink(linkEntity.id);
		const segmentIndex = targetLineSegment.index;
		if (!sublinkEntityId) {
			// eslint-disable-next-line max-len
			console.log(`getFirstSublinkForLink lookup failed for link ${linkEntity.id} (id number ${linkEntity.linkId})`);
			return undefined;
		}

		// Ensure that a new link ID can be generated
		const nextAvaialbleIdForLink = store.mapStore.getNextAvailableLinkId(1)[0];
		if (!nextAvaialbleIdForLink) {
			alertToast(ERROR_MAXIMUM_LINK_ID, 'error');
			return undefined;
		}

		// Get the ordered sublinks and then determine the index of the sublink before the break (sublinkIndex)
		const orderedSublinks = linkEntity.getSublinks();
		// const orderedSublinks = lookup.getOrderedSublinks(sublinkEntityId); // TODO: update with byPrevIds
		const sublinkIndex = this.getSublinkIndex(orderedSublinks, segmentIndex);

		// lastNode is the node at which the original link will now end
		// it's found by: 1. getting the start node, 2. ordering the nodes 3. getting the end node
		let lastNode = lookup.getFirstNodeForSublink(orderedSublinks[sublinkIndex].id);
		if (!lastNode) {
			console.log('unable to find node');
			return undefined;
		}

		lastNode = orderedSublinks[sublinkIndex].getNodes().slice(-1)[0];
		const firstNodeForNextSublink = lookup.getFirstNodeForSublink(orderedSublinks[sublinkIndex + 1].id);
		return {
			lastNode: lastNode,
			firstNodeForNextSublink: firstNodeForNextSublink,
			orderedSublinks: orderedSublinks,
			nextAvaialbleIdForLink: nextAvaialbleIdForLink,
			sublinkIndex: sublinkIndex,
			link: link,
			segmentIndex: segmentIndex,
		};
	}

	/**
	 * Gets the index for the sublink
	 * @param orderedSublinks
	 * @param segmentIndex
	 */
	private static getSublinkIndex = (orderedSublinks: SublinkEntity[], segmentIndex: number): number => {
		let nodeCount = 0;
		return orderedSublinks.findIndex(sl => {
			nodeCount += sl.nodess.length;
			return ((nodeCount - 1) === segmentIndex);
		});
	}

	/**
	 * Process data, set refs and re-render links after break link operation.
	 * Saving data hands over to onTrackBreakLink.
	 * @param eventHandler 
	 * @param coords 
	 * @param link 
	 */
	public static async breakLink(eventHandler: MapEventHandler, coords: PixiCoordinates, link: Link) {
		const stateHandler = eventHandler.getStateHandler();
		const linkHandler = stateHandler instanceof ConfirmedPathSelectHandler ? stateHandler : undefined;
		if (!!linkHandler) {
			// TODO: find better way. This is a hack to fix conflict between HIT-278 and HIT-97 whereby break link
			// causes a crash due to connectivity graphic. Before breaking, the link remove the connectivity graphic
			// and after the operation re-create it with the updated link data (see renderConnectivity at end of method)
			// console.log('BreakLink: calling hideConnectivity');
			linkHandler.getLinkConnectivityDisplayHelper().hideConnectivity();
		} else {
			console.log('BreakLink: not calling hideConnectivity');
		}

		const targetLineSegment = this.hittestByLineSegment(link, coords);
		const nextSublinkId = eventHandler.getLookup().getNextAvailableSublinkId(1)[0];
		const nextLinkId = eventHandler.getLookup().getNextAvailableLinkId(1)[0];

		if (!targetLineSegment) {
			return;
		}

		eventHandler.getController().getTracker().addChange(
				new BreakLinkCommand(link.getLinkEntity().id, targetLineSegment.index, nextLinkId, nextSublinkId));
	}

	/**
	 * Check two node tasks for joining sublinks.
	 * Used to prevent showing 'Join sublink' in the ContextMenu.
	 */
	public static checkNodesTasksToJoinSublinks(eventHandler: MapEventHandler, coords: PixiCoordinates, link: Link) {
		// Get (1) the last node of the prev sublink and (2) the first node of the next sublink
		const params = this.getBreakLinkBetweenSublinksParams(eventHandler, coords, link);

		let isSameTask = false;
		if (!params) {
			return isSameTask;
		}

		const { lastNode, firstNodeForNextSublink } = params;
		isSameTask = lastNode.task === 'HAULING' && lastNode.task === firstNodeForNextSublink?.task;

		return isSameTask;
	}

	/**
	 * Returns the params necessary to break a sublink. Also used to determine whether or not a sublink
	 * can be broken. This method has no side-effects.
	 * 
	 * @param eventHandler 
	 * @param coords 
	 * @param link 
	 * @param sublink 
	 * @returns params necessary to break sublink (if possible) otherwise undefined
	 */ 
	// eslint-disable-next-line max-len
	public static getBreakSublinkParams(eventHandler: MapEventHandler, coords: PixiCoordinates, link: Link, sublink: Sublink): IBreakSublinkParams | undefined {
		const targetLineSegment = this.hittestByLineSegment(sublink, coords);
		if (!targetLineSegment) {
			return undefined;
		}

		console.log(`Got sublink ${sublink?.getId()} at segment ${targetLineSegment?.index} `);
		// Use segment information to calculate nodes
		const lineSegmentIndex = targetLineSegment.index;
		let originalNodes: NodeEntity[] = [];
		let splitNodes: NodeEntity[] = [];
		const sublinkEntity = sublink.getSublinkEntity();
		const node = eventHandler.getLookup().getFirstNodeForSublink(sublinkEntity.id);
		// Split into two sets of nodes (one set for each sublink)
		if (node) {
			// For imported links, node order needs to be corrected
			originalNodes = sublinkEntity.getNodes();

			// For validation, pre-calculate what the number of nodwa of each sublink would be after the split
			const sublink1NumberOfNodes = lineSegmentIndex + 1;
			const sublink2NumberOfNodes = originalNodes.length - lineSegmentIndex - 1;
			console.log(`length: s1: ${sublink1NumberOfNodes} s2: ${sublink2NumberOfNodes}`);
			if (sublink1NumberOfNodes < 2 || sublink2NumberOfNodes < 2) {
				// alertToast('Invalid operation: sublinks with less than 2 nodes are not allowed' , 'error');
				console.log('Invalid operation: sublinks with less than 2 nodes are not allowed');
				return undefined;
			}
			const ids = `nodes ${originalNodes[lineSegmentIndex].nodeId} ${originalNodes[lineSegmentIndex + 1].nodeId}`;
			console.log(ids);
			// click point is between lineSegmentIndex and lineSegmentIndex + 1
			// split such that the two sets of nodes are [0] to [lineSegmentIndex] and [lineSegmentIndex + 1] to [lastIndex]
			splitNodes = originalNodes.slice(lineSegmentIndex + 1); // Do we need to splitNodes here? or else check for splitNodes count>=2
		} else {
			console.log('breaksublink: unable to find nodes');
			return undefined;
		}

		// The two sublinks consist of the original sublink (shortened) and a new sublink
		// Hence, an ID needs to generated for the new sublink (ID is unchanged for original sublink)
		const nextAvaialbleIdForSublink = store.mapStore.getNextAvailableSublinkId(1)[0];
		if (!nextAvaialbleIdForSublink) {
			alertToast(ERROR_MAXIMUM_SUBLINK_ID, 'error');
			return undefined;
		}
		return {
			originalNodes: originalNodes, // we don't need to return
			splitNodes: splitNodes, // we don't need to return
			lineSegmentIndex: lineSegmentIndex,
			nextAvaialbleIdForSublink: nextAvaialbleIdForSublink,
			link: link,
			sublink: sublink,
		};
	}

	/**
	 * Process data, set refs and re-render link after break sublink operation.
	 * Saving data hands over to onTrackBreakSublink.
	 * @param eventHandler
	 * @param params
	 */
	@action
	public static async breakSublink(eventHandler: MapEventHandler, params: IBreakSublinkParams) {
		const {
			lineSegmentIndex, nextAvaialbleIdForSublink, link, sublink,
		} = params;

		eventHandler.getController().getTracker().addChange(
			new BreakSublinkCommand(
				link.getLinkEntity().id,
				lineSegmentIndex,
				sublink.getSublinkEntity().id,
				nextAvaialbleIdForSublink));
	}

	static copyLink(link: LinkEntity): LinkEntity {
		const newLink = new LinkEntity({ ...link });

		newLink.sublinkss = newLink.sublinkss.map(x => {
			x.validateRefs();
			const newSublink = new SublinkEntity(x);

			newSublink.nodess = x.nodess.map(y => {
				y.validateRefs();
				return new NodeEntity(y);
			});

			return newSublink;
		});

		newLink.linkTos = newLink.linkTos.map(x => new LinkFromLinkTo(x));
		newLink.linkFroms = newLink.linkFroms.map(x => new LinkFromLinkTo(x));

		return newLink;
	}
}
