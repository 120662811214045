import MapStateHandler from "../MapStateHandler";
import {LeafletMouseEvent} from "leaflet";
import {LeafletCoordinates} from "../../Helpers/Coordinates";
import {HoverState} from "../../MapStateHandlerHelpers/PathToolHelper";
import {calcHeading} from "../../Helpers/MapUtils";
import {isCtrlKeyHeld, isShiftKeyHeld} from "../MapGlobalEventHandler";
import Link from "../../MapObjects/Link/Link";
import Path from "../../MapObjects/Path/Path";
import ConfirmedPathManager from "./Helpers/ConfirmedPathManager";

type ConfirmedPathEditHandlerParams = Link | undefined;

export default class ConfirmedPathEditHandler extends MapStateHandler<ConfirmedPathEditHandlerParams> {

	private state: ConfirmedPathManager;

	private ignoreDispose: boolean = false;

	private hoverState = HoverState.DEFAULT;
	private hoveringWaypointId: string | undefined = undefined;

	private originalPath: Path;

	private hasBeenEdited: boolean = false;

	onInit(initialState: ConfirmedPathEditHandlerParams): Promise<void> | void {
		if (initialState == undefined) {
			this.getEventHandler().setActiveTool('selector');
			return;
		}

		this.originalPath = initialState.getParent() as Path;
		const originalLink = initialState.getLinkEntity();

		this.showOriginalPath(false, false);

		// Create a new path manager with the new and original link
		this.state = new ConfirmedPathManager(this, originalLink);
		this.state.init();
	}

	onMove(event: LeafletMouseEvent): Promise<void> | void {
		[this.hoverState, this.hoveringWaypointId] = this.state.renderHelper.isHoveringOverWaypoint(event.latlng);

		if (this.hoverState === HoverState.WAYPOINT) {
			this.state.renderHelper.setMoveCursor();
		} else if (this.hoverState == HoverState.HEADING_BACK || this.hoverState == HoverState.HEADING_FRONT) {
			this.state.renderHelper.setRotateCursor();
		} else {
			this.state.renderHelper.setDefaultCursor();
		}
	}

	onDoubleClick(event: LeafletMouseEvent): Promise<void> | void {
		// Create/Delete a mid waypoint if clicking on a node
		const tryAddMidwaypoint = this.state.tryToggleMidWaypoint(event.latlng, () => this.state.stopAction());

		if (!tryAddMidwaypoint) {
			// If clicking outside the nodes, confirm path
			this.onConfirm();
		}
	}

	onDragStart(event: LeafletMouseEvent, originalCoordinates: LeafletCoordinates): Promise<void> | void {
	}

	onDragMove(event: LeafletMouseEvent): Promise<void> | void {
		if (this.hoverState === HoverState.DEFAULT || !this.hoveringWaypointId) {
			return;
		}

		const waypoint = this.state.waypoints.getWaypoint(this.hoveringWaypointId);
		if (!waypoint) {
			return;
		}

		// Remove the snap if needed
		this.state.waypoints.unSnapWaypoint(waypoint.id);

		const coords = this.getRenderer().getRealWorldCoords(event.latlng);
		const isStraightOperation = isShiftKeyHeld(event.originalEvent);

		if (this.hoverState === HoverState.WAYPOINT) {
			const previousWaypoint = isStraightOperation ? this.state.waypoints.getWaypointBefore(this.hoveringWaypointId) : undefined;
			if (isStraightOperation && previousWaypoint) {
				// Set the same heading
				waypoint.setHeading(previousWaypoint.heading);
				waypoint.coordinates = this.state
					.getStraightWaypointLocation(previousWaypoint.coordinates, coords, previousWaypoint.heading);
			} else {
				waypoint.coordinates = coords;
			}
		} else if (this.hoverState === HoverState.HEADING_FRONT) {
			waypoint.setHeading(calcHeading(waypoint.coordinates, coords, waypoint.direction === 'reverse'));
		} else if (this.hoverState === HoverState.HEADING_BACK) {
			// When we are moving the back heading handle, we want to reverse the direction to get the correct heading
			waypoint.setHeading(calcHeading(coords, waypoint.coordinates, waypoint.direction === 'reverse'));
		}

		this.state.processPathUpdate();
	}

	onDragEnd(event: LeafletMouseEvent): Promise<void> | void {
		if (this.hoverState === HoverState.DEFAULT || !this.hoveringWaypointId) {
			return;
		}

		const coords = this.getRenderer().getRealWorldCoords(event.latlng);
		const isSnapOperation = isCtrlKeyHeld(event.originalEvent);
		const isStraightOperation = isShiftKeyHeld(event.originalEvent);

		if (isSnapOperation && isStraightOperation || !this.hoveringWaypointId) {
			this.state.stopAction();
			return;
		}

		const waypoint = this.state.waypoints.getWaypoint(this.hoveringWaypointId);
		if (waypoint && isSnapOperation) {
			const snapOption = this.state.snapHelper.checkForSnap(coords, waypoint);
			if (snapOption) {
				waypoint.shortClicked = false;
				waypoint.setHeading(snapOption.targetHeading);
				waypoint.coordinates = snapOption.targetLocation;

				this.state.waypoints.handleSnapOption(snapOption);
			}
		}

		this.hoveringWaypointId = undefined;
		this.hasBeenEdited = true;

		this.state.stopAction();
	}

	onKeyPress(event: KeyboardEvent): Promise<void> | void {
		if (this.getEventHandler().isEnter(event)) {
			this.onConfirm();
		}
	}

	onEscapePressed(event: KeyboardEvent): Promise<void> | void {
		this.cancelEdit();
	}

	onRequestUpdate(entity?: unknown): Promise<void> | void {
		// Process any inputs from the properties panel
		this.state.processPathUpdate();
	}

	onConfirm(): Promise<void> | void {
		if (!this.hasBeenEdited) {
			this.cancelEdit();
			return;
		}

		if (this.state.confirmPath()) {
			this.deleteOriginalPath();

			this.state.dispose();
			this.ignoreDispose = true;

			// Go back to the select tool with the path object selected
			const linkMapObject = this.state.renderHelper.getPathObject()
				?.getChildren().find(x => x.getType() === 'link') as Link;
			this.getEventHandler().setActiveTool('selector', {
				mapObject: linkMapObject,
			});
		}
	}

	dispose(): Promise<void> | void {
		if (this.ignoreDispose) {
			return;
		}

		this.showOriginalPath(true);

		this.state.dispose();
	}

	private cancelEdit() {
		this.state.renderHelper.clearDisplay(false);

		this.getEventHandler().setActiveTool('selector', {
			mapObject: this.originalPath.getChildren().find(x => x.getType() === 'link') as Link,
		});
	}

	private showOriginalPath(show: boolean, render: boolean = true) {
		this.originalPath.setRenderable(show);

		if (render) {
			setTimeout(() => this.getRenderer().rerender(), 0);
		}
	}

	private deleteOriginalPath() {
		this.getRenderer().removeObject(this.originalPath.getId());
		setTimeout(() => this.getRenderer().rerender(), 0);
	}

}